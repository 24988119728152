/* eslint-disable @nx/enforce-module-boundaries */
import { Card, Stack } from '@mybridge/ui';
import { forwardRef } from 'react';
import { PostCardSkeleton } from 'v4/components/post/skeleton';
export const SearchCardSkeleton = forwardRef(({ ...props }, ref) => {
  return (
    <>
      <Stack spacing={6}>
        <Card p={2}>
          <PostCardSkeleton />
        </Card>
        <Card p={2}>
          <PostCardSkeleton withImage />
        </Card>
        <Card p={2}>
          <PostCardSkeleton />
        </Card>
        <Card p={2}>
          <PostCardSkeleton withImage />
        </Card>
      </Stack>
    </>
  );
});
