/* eslint-disable @nx/enforce-module-boundaries */
'use client';
import {
  Box,
  SkeletonCircle,
  SkeletonText,
  Skeleton,
  HStack,
} from '@mybridge/ui';
import { forwardRef } from 'react';
export const PostCardSkeleton = forwardRef(({ ...props }, ref) => {
  return (
    <>
      <Box>
        <HStack gap={3} justifyContent="flex-start">
          <SkeletonCircle size="10" />
          <SkeletonText
            mt="4"
            noOfLines={3}
            spacing="2"
            skeletonHeight="2"
            mb={4}
          />
        </HStack>
        <SkeletonText
          mt="4"
          noOfLines={3}
          spacing="2"
          skeletonHeight="2"
          mb={4}
        />
        {props.withImage && <Skeleton height={['150px', '300px']} />}
      </Box>
    </>
  );
});
